import { useUser } from '@/hooks';
import { useRouter } from 'next/router';

export const AuthenticatedRoute = ({ children }) => {
  const { loading, isLoggedIn } = useUser();
  const router = useRouter();

  if (loading) {
    return null;
  }

  if (!isLoggedIn) {
    router.push('/login');
    return null;
  }

  return children;
};

export const pages = [
  {
    label: 'Product page',
    value: 'product',
  },
  { label: 'Cart page', value: 'cart' },
];

export const algorithms = [
  { label: 'AI Recommendations', value: 'ai_recommendations' },
  { label: 'Manual Recommendations', value: 'manual' },
  { label: 'Recently Viewed', value: 'recently_viewed' },
  // { label: 'Most Popular', value: 'popular' },
];

import { AuthenticatedRoute } from '@/components/auth';
import Home from '@/components/home/index';
import { shopify } from '@/lib/shopify';
import { initializeApollo, addApolloState } from '@/lib/apollo';

import { ME, GET_STORE, ACTIVE_OFFERS, UPDATE_STORE, FEATURES } from '@/query';

const Page = () => (
  <AuthenticatedRoute>
    <Home />
  </AuthenticatedRoute>
);

// checks users plan in our database as a fallback
const checkPlan = async (store, apollo) => {
  if (!store) return;

  const { appInstallation: app, plan } = store;

  if (app.partnerDevelopment) return;

  // update store if no subscription
  if (app.subscriptions.length === 0 && plan) {
    await apollo.mutate({
      mutation: UPDATE_STORE,
      variables: {
        input: {
          plan: null,
        },
      },
      context: {
        store,
      },
    });
  }
};

export async function getServerSideProps({ query }) {
  const { hmac, shop: domain } = query;

  // if embedded, validate and fetch data on the backend
  if (hmac && domain) {
    const apolloClient = initializeApollo();

    if (await shopify.utils.validateHmac(query)) {
      const [store] = await Promise.all([
        apolloClient.query({
          query: GET_STORE,
          context: {
            store: {
              domain,
            },
          },
        }),
        apolloClient.query({
          query: ME,
          context: {
            store: {
              domain,
            },
          },
        }),
        apolloClient.query({
          query: ACTIVE_OFFERS,
          variables: {
            offset: 0,
            limit: 50,
            input: {
              active: null,
              isDashboard: true,
              query: null,
              types: [
                'bundle',
                'addon',
                'post-purchase',
                'recommendation',
                'volume',
                'popup-upsell',
                'popup-cross-sell',
                'cart-recommendation',
              ],
              sortBy: 'created_at desc',
            },
          },
          context: {
            store: {
              domain,
            },
          },
        }),
        apolloClient.query({
          query: FEATURES,
          context: {
            store: {
              domain,
            },
          },
        }),
      ]);

      // await checkPlan(store.data?.store, apolloClient);

      return addApolloState(apolloClient, {
        props: {},
      });
    }
  }

  return {
    props: {},
  };
}

export default Page;

import { useCallback } from 'react';
import {
  ChoiceList,
  IndexFilters,
  useSetIndexFiltersMode,
} from '@shopify/polaris';

const tabStrings = [
  { label: 'All', value: null },
  { label: 'Active', value: true },
  { label: 'Disabled', value: false },
];

const sortOptions = [
  { label: 'Created At', value: 'created_at' },
  { label: 'Revenue', value: 'revenue' },
  { label: 'Orders', value: 'orders' },
  { label: 'Views', value: 'impressions' },
]
  .map(({ label, value }) => [
    {
      label,
      value: `${value} asc`,
      directionLabel: 'Ascending',
    },
    {
      label,
      value: `${value} desc`,
      directionLabel: 'Descending',
    },
  ])
  .flatMap((item) => item);

const Filters = ({
  type,
  setType,
  queryValue,
  setQueryValue,
  status,
  setStatus,
  setSortBy,
  sortBy,
}) => {
  const handleTypeChange = useCallback((value) => setType(value), [setType]); // type change;
  const handleTypeRemove = useCallback((value) => setType(null), [setType]); // type remove;
  const handleActiveChange = useCallback(
    (value) => setStatus(value),
    [setStatus]
  ); // active change;
  const handleActiveRemove = useCallback(
    (value) => setStatus(value),
    [setStatus]
  ); // active remove;
  const handleSortChange = useCallback(
    (value) => setSortBy(value),
    [setSortBy]
  ); // sort change;
  const handleQueryValueRemove = useCallback(
    () => setQueryValue(null),
    [setQueryValue]
  );
  const { mode, setMode } = useSetIndexFiltersMode();

  const handleClearAll = useCallback(() => {
    handleTypeRemove();
    handleQueryValueRemove();
    handleActiveRemove();
  }, [handleTypeRemove, handleQueryValueRemove]);

  const tabs = tabStrings.map((item, index) => ({
    id: `${item.value}-${index}`,
    index,
    onAction: () => handleActiveChange(item.value),
    content: item.label,
    isLocked: index === 0,
  }));
  const selectedTab = tabStrings.indexOf(
    tabStrings.find((tab) => tab.value === status)
  );

  const filters = [
    {
      key: 'type',
      label: 'Type',
      filter: (
        <ChoiceList
          title="Widget type"
          titleHidden
          choices={[
            {
              label: 'Frequently bought together',
              value: 'bundle',
              directionLabel: '',
            },
            { label: 'Product add-ons', value: 'addon', directionLabel: '' },
            { label: 'Volume discount', value: 'volume', directionLabel: '' },
            {
              label: 'Product recommendations',
              value: 'recommendation',
              directionLabel: '',
            },
            {
              label: 'Post purchase offer',
              value: 'post-purchase',
              directionLabel: '',
            },
            {
              label: 'Free gift',
              value: 'free-gift',
              directionLabel: '',
            },
            {
              label: 'Popup upsell',
              value: 'popup-upsell',
              directionLabel: '',
            },
            {
              label: 'Popup cross-sell',
              value: 'popup-cross-sell',
              directionLabel: '',
            },
            {
              label: 'In-cart recommendations',
              value: 'cart-recommendation',
              directionLabel: '',
            },
            {
              label: 'Checkout upsell',
              value: 'checkout-upsell',
              directionLabel: '',
            },
          ]}
          selected={type || []}
          onChange={handleTypeChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(type)) {
    const key = 'type';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, type),
      onRemove: handleTypeRemove,
    });
  }

  return (
    <IndexFilters
      queryValue={queryValue}
      filters={filters}
      appliedFilters={appliedFilters}
      onQueryChange={setQueryValue}
      onQueryClear={handleQueryValueRemove}
      onClearAll={handleClearAll}
      tabs={tabs}
      sortSelected={sortBy}
      mode={mode}
      selected={selectedTab}
      setMode={setMode}
      onSort={handleSortChange}
      sortOptions={sortOptions}
      canCreateNewView={false}
      cancelAction={{
        onAction: () => {},
        disabled: false,
        loading: false,
      }}
    />
  );
};

function disambiguateLabel(key, value) {
  switch (key) {
    case 'type':
      return `Types include ${value.join(', ')}`;
    default:
      return value;
  }
}

function isEmpty(value) {
  if (Array.isArray(value)) {
    return value.length === 0;
  }
  return value === '' || value == null;
}

export default Filters;
